import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import ProductionPartNotice from '@/domain/ProductionPartNotice';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from './dataAccessResponse';
import NoContentDataAccessResponse from '@/types/api/NoContentDataAccessResponse';

export default class ProductionPartNoticeApiService {
    public async getAllFailedProductionPartNotices(): Promise<DataAccessResponse<ProductionPartNotice[]>> {
        const response: AxiosResponse<DataAccessResponse<ProductionPartNotice[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/ProductionPartNotice/failed`,
        });

        return new DataListAccessResponseClass<ProductionPartNotice>(response, ProductionPartNotice).response;
    }

    public async rejectFailedProductionPartNotice(noticeId: number): Promise<boolean> {
        const response: AxiosResponse<NoContentDataAccessResponse> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ProductionPartNotice/failed-rejection/${noticeId}`,
        });

        return response.data.success;
    }

    public async revalidateAllFailedProductionPartNotices(): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ProductionPartNotice/failed-revalidation`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async revalidateFailedProductionPartNotice(noticeId: number): Promise<DataAccessResponse<string[]>> {
        const response: AxiosResponse<DataAccessResponse<string[]>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ProductionPartNotice/failed-revalidation/${noticeId}`,
        });

        return new DataListAccessResponseClass<string>(response).response;
    }

    public async getProductionPartNotice(id: number): Promise<DataAccessResponse<ProductionPartNotice>> {
        const response: AxiosResponse<DataAccessResponse<ProductionPartNotice>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/ProductionPartNotice/${id}`,
        });

        return new DataAccessResponseClass<ProductionPartNotice>(response, ProductionPartNotice).response;
    }

    public async updateProductionPartNotice(notice: ProductionPartNotice): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ProductionPartNotice/update`,
            data: notice,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
