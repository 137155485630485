import SideBarMenuItem from '@/components/sidebar/domain/SideBarMenuItem';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import ISideBar from '@/interfaces/ISideBar';
import Permissions from '@/services/permissions/Permissions';
import SystemApiService from '@/services/api/SystemApiService';
import PushNotificationSubscription from '@/store/utility/PushNotificationSubscription';
import NotificationStoreEvents from '@/constants/NotificationStoreEvents';
import IncomingForecastService from '@/services/IncomingForecastService';
import ProductionPartNoticeService from '@/services/ProductionPartNoticeService';
import ShippingNoticeService from '@/services/ShippingNoticeService';
import PartnerOrderService from '@/services/PartnerOrderService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import SideBarMenuChild from '@/components/sidebar/domain/SideBarMenuChild';

export default class MasterDataSideBar implements ISideBar {
    private systemApiService: SystemApiService;
    public menuItems: SideBarMenuItem[] = [];

    constructor() {
        this.systemApiService = new SystemApiService();
    }

    public updateMenuItems() {
        this.menuItems = [
            {
                id: 10,
                title: getTitleCaseTranslation('core.common.dashboard'),
                icon: 'chart-line',
                routeName: MasterDataRouteTypes.DASHBOARD,
                type: SideBarMenuItem.TYPE.BUTTON,
                show: Permissions.ADMINISTRATION.canAccessDashboard(),
            },
            {
                id: 20,
                title: getTranslation('core.domain.operations').toUpperCase(),
                type: SideBarMenuItem.TYPE.PARENT,
                isSectionHeader: true,
                isDefaultExpanded: true,
                show: true,
                children: [
                    {
                        id: 210,
                        title: getTranslation('core.button.allTransactions'),
                        icon: 'pallet',
                        routeName: MasterDataRouteTypes.TRANSACTION.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.TRANSACTION.LIST],
                        type: SideBarMenuItem.TYPE.BUTTON,
                        show: Permissions.ADMINISTRATION.canEditTransactions(),
                    },
                    {
                        id: 220,
                        title: getTranslation('core.common.plannedTransactions'),
                        icon: 'pallet',
                        routeName: MasterDataRouteTypes.TRANSACTION.PLANNING,
                        activeRouteNameArray: [MasterDataRouteTypes.TRANSACTION.PLANNING],
                        type: SideBarMenuItem.TYPE.BUTTON,
                        show: Permissions.ADMINISTRATION.canEditTransactions(),
                    },
                    {
                        id: 230,
                        title: getTitleCaseTranslation('core.domain.inventory'),
                        icon: 'box',
                        routeName: MasterDataRouteTypes.INVENTORY.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.INVENTORY.LIST],
                        type: SideBarMenuItem.TYPE.BUTTON,
                        show: Permissions.ADMINISTRATION.canViewInventory(),
                    },
                    {
                        id: 235,
                        title: getTitleCaseTranslation('core.domain.itemTransformation'),
                        icon: 'box',
                        routeName: MasterDataRouteTypes.INVENTORY.ITEM_TRANSFORMATION,
                        activeRouteNameArray: [MasterDataRouteTypes.INVENTORY.ITEM_TRANSFORMATION],
                        type: SideBarMenuItem.TYPE.BUTTON,
                        show: Permissions.ADMINISTRATION.canTransformItem(),
                    },
                    {
                        id: 240,
                        title: getTitleCaseTranslation('core.domain.cycleCount'),
                        icon: 'box',
                        routeName: MasterDataRouteTypes.CYCLE_COUNT_REPORT.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.CYCLE_COUNT_REPORT.LIST],
                        type: SideBarMenuItem.TYPE.BUTTON,
                        show: Permissions.ADMINISTRATION.canEditCycleCounts(),
                    },
                    {
                        id: 250,
                        title: getTitleCaseTranslation('core.domain.shippingPlanner'),
                        icon: 'box',
                        routeName: MasterDataRouteTypes.SHIPPING_PLANNER,
                        activeRouteNameArray: [MasterDataRouteTypes.SHIPPING_PLANNER],
                        type: SideBarMenuItem.TYPE.BUTTON,
                        show: Permissions.ADMINISTRATION.canViewShippingPlanner(),
                    },
                    {
                        id: 260,
                        title: getTitleCaseTranslation('core.domain.importErrors'),
                        icon: 'database',
                        show: Permissions.ADMINISTRATION.canResolveImportErrors(),
                        type: 'parent',
                        pushNotificationSubscription: new PushNotificationSubscription(NotificationStoreEvents.MASTER_DATA.IMPORT_ERRORS.ALL),
                        children: [
                            {
                                id: 2610,
                                title: getTitleCaseTranslation('core.domain.shippingNotice'),
                                routeName: MasterDataRouteTypes.IMPORT_ERRORS.ASN,
                                activeRouteNameArray: [MasterDataRouteTypes.IMPORT_ERRORS.ASN],
                                show: true,
                                pushNotificationSubscription: new PushNotificationSubscription(
                                    NotificationStoreEvents.MASTER_DATA.IMPORT_ERRORS.ASN,
                                    async () => (await new ShippingNoticeService().getAllFailed()).length,
                                ),
                            },
                            {
                                id: 2620,
                                title: getTitleCaseTranslation('core.domain.demandForecast'),
                                routeName: MasterDataRouteTypes.IMPORT_ERRORS.IDF,
                                activeRouteNameArray: [MasterDataRouteTypes.IMPORT_ERRORS.IDF],
                                show: true,
                                pushNotificationSubscription: new PushNotificationSubscription(
                                    NotificationStoreEvents.MASTER_DATA.IMPORT_ERRORS.IDF,
                                    async () => (await new IncomingForecastService().getAllFailed()).length,
                                ),
                            },
                            {
                                id: 2630,
                                title: getTranslation('core.domain.productionPart'),
                                routeName: MasterDataRouteTypes.IMPORT_ERRORS.PFEP,
                                activeRouteNameArray: [MasterDataRouteTypes.IMPORT_ERRORS.PFEP],
                                show: true,
                                pushNotificationSubscription: new PushNotificationSubscription(
                                    NotificationStoreEvents.MASTER_DATA.IMPORT_ERRORS.PFEP,
                                    async () => (await new ProductionPartNoticeService().getAllFailed()).length,
                                ),
                            },
                            {
                                id: 2640,
                                title: getTranslation('core.domain.partnerOrder'),
                                routeName: MasterDataRouteTypes.IMPORT_ERRORS.PO,
                                activeRouteNameArray: [MasterDataRouteTypes.IMPORT_ERRORS.PO],
                                show: true,
                                pushNotificationSubscription: new PushNotificationSubscription(
                                    NotificationStoreEvents.MASTER_DATA.IMPORT_ERRORS.PARTNER_ORDER,
                                    async () => (await new PartnerOrderService().getAllFailed()).length,
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                id: 30,
                title: getTranslation('core.common.admin').toUpperCase(),
                type: SideBarMenuItem.TYPE.PARENT,
                isSectionHeader: true,
                show: Permissions.ADMINISTRATION.canAccessUserAdmin(),
                children: [
                    {
                        id: 310,
                        title: getTitleCaseTranslation('core.domain.users'),
                        icon: 'users',
                        routeName: MasterDataRouteTypes.USER.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.USER.LIST],
                        show: Permissions.ADMINISTRATION.canEditUsers(),
                    },
                    {
                        id: 320,
                        title: getTitleCaseTranslation('core.domain.roles'),
                        icon: 'id-badge',
                        routeName: MasterDataRouteTypes.ROLES.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.ROLES.LIST],
                        show: Permissions.ADMINISTRATION.canEditRoles(),
                    },
                    {
                        id: 330,
                        title: getTitleCaseTranslation('core.domain.config'),
                        icon: 'toolbox',
                        show: Permissions.ADMINISTRATION.canEditConfig(),
                        type: SideBarMenuChild.TYPE.PARENT,
                        children: [
                            {
                                id: 3310,
                                title: getTitleCaseTranslation('core.domain.locationTypes'),
                                routeName: MasterDataRouteTypes.CONFIG.LOCATION_TYPE,
                                activeRouteNameArray: [MasterDataRouteTypes.CONFIG.LOCATION_TYPE],
                                show: true,
                            },
                            {
                                id: 3320,
                                title: getTitleCaseTranslation('core.common.itemTypes'),
                                routeName: MasterDataRouteTypes.CONFIG.ITEM_TYPE,
                                activeRouteNameArray: [MasterDataRouteTypes.CONFIG.ITEM_TYPE],
                                show: true,
                            },
                            {
                                id: 3330,
                                title: getTitleCaseTranslation('core.domain.trailerTypes'),
                                routeName: MasterDataRouteTypes.CONFIG.TRAILER_TYPE,
                                activeRouteNameArray: [MasterDataRouteTypes.CONFIG.TRAILER_TYPE],
                                show: true,
                            },
                            {
                                id: 3340,
                                title: getTitleCaseTranslation('core.common.freightCodes'),
                                routeName: MasterDataRouteTypes.CONFIG.FREIGHT_CODE,
                                activeRouteNameArray: [MasterDataRouteTypes.CONFIG.FREIGHT_CODE],
                                show: true,
                            },
                            {
                                id: 3350,
                                title: getTitleCaseTranslation('core.common.equipmentTypes'),
                                routeName: MasterDataRouteTypes.CONFIG.EQUIPMENT_TYPE,
                                activeRouteNameArray: [MasterDataRouteTypes.CONFIG.EQUIPMENT_TYPE],
                                show: true,
                            },
                        ],
                    },
                    {
                        id: 340,
                        title: getTitleCaseTranslation('core.domain.settings'),
                        icon: 'cog',
                        routeName: MasterDataRouteTypes.CUSTOMER_SETTINGS.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.CUSTOMER_SETTINGS.LIST],
                        type: SideBarMenuItem.TYPE.BUTTON,
                        show: Permissions.ADMINISTRATION.canEditCustomerSettings(),
                    },
                ],
            },
            {
                id: 40,
                title: getTranslation('core.domain.masterData').toUpperCase(),
                type: SideBarMenuItem.TYPE.PARENT,
                isSectionHeader: true,
                show: true,
                children: [
                    {
                        id: 410,
                        title: getTitleCaseTranslation('core.domain.locations'),
                        icon: 'map-pin',
                        type: SideBarMenuItem.TYPE.BUTTON,
                        routeName: MasterDataRouteTypes.LOCATION.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.LOCATION.LIST],
                        show: Permissions.ADMINISTRATION.canEditLocations(),
                    },
                    {
                        id: 420,
                        title: getTitleCaseTranslation('core.domain.items'),
                        icon: 'box',
                        type: SideBarMenuItem.TYPE.BUTTON,
                        routeName: MasterDataRouteTypes.ITEM.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.ITEM.LIST],
                        show: Permissions.ADMINISTRATION.canEditItems(),
                    },
                    {
                        id: 430,
                        title: getTitleCaseTranslation('core.domain.carriers'),
                        icon: 'truck',
                        type: SideBarMenuItem.TYPE.BUTTON,
                        routeName: MasterDataRouteTypes.CARRIER.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.CARRIER.LIST],
                        show: Permissions.ADMINISTRATION.canEditCarriers(),
                    },
                    {
                        id: 440,
                        title: getTranslation('core.domain.productionPart'),
                        icon: 'box',
                        type: SideBarMenuItem.TYPE.BUTTON,
                        routeName: MasterDataRouteTypes.PRODUCTION_PART.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.PRODUCTION_PART.LIST],
                        show: Permissions.ADMINISTRATION.canEditProductionPartData(),
                    },
                    {
                        id: 450,
                        title: getTitleCaseTranslation('core.domain.repairParts'),
                        icon: 'wrench',
                        type: SideBarMenuItem.TYPE.BUTTON,
                        routeName: MasterDataRouteTypes.REPAIR_PART.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.REPAIR_PART.LIST],
                        show: Permissions.ADMINISTRATION.canRepair(),
                    },
                    {
                        id: 460,
                        title: getTitleCaseTranslation('core.domain.equipment'),
                        icon: 'dolly',
                        type: SideBarMenuItem.TYPE.BUTTON,
                        routeName: MasterDataRouteTypes.EQUIPMENT.LIST,
                        activeRouteNameArray: [MasterDataRouteTypes.EQUIPMENT.LIST],
                        show: Permissions.ADMINISTRATION.canEditEquipment(),
                    },
                ],
            },
        ];
    }
}
