

import {
    defineComponent, reactive, onBeforeMount, ref, Ref,
} from 'vue';
import MasterDataSideBar from '@/modules/master-data/routes/MasterDataSideBar';
import SideBarNav from '@/components/sidebar/SideBarNav.vue';
import SideBarMenuItem from '@/components/sidebar/domain/SideBarMenuItem';
import coreStore from '@/store/CoreStore';

export default defineComponent({
    name: 'master-data',
    components: {
        SideBarNav,
    },
    props: {},
    setup() {
        const menuItems: SideBarMenuItem[] = reactive([]);
        const pushNotificationCounts = ref(new Map<string, Ref<number>>());
        const { pushNotificationStore } = coreStore.getInstance();
        const sideBar = new MasterDataSideBar();

        async function subscribePushNotification(sideBarMenuItems: SideBarMenuItem[]): Promise<void> {
            const counts = new Map<string, Ref<number>>();

            const initialValuePromises = sideBarMenuItems.filter((menuItem) => !!menuItem.pushNotificationSubscription && menuItem.show)
                .map(async (menuItem) => ({
                    key: menuItem.pushNotificationSubscription!.key,
                    initialValue: await menuItem.pushNotificationSubscription!.initializationCallback?.() || 0,
                }));

            sideBarMenuItems.forEach((item) => {
                const childWithSubscriptions = item.children?.filter((child) => !!child.pushNotificationSubscription && child.show)
                    .map(async (childItem) => ({
                        key: childItem.pushNotificationSubscription!.key,
                        initialValue: await childItem.pushNotificationSubscription!.initializationCallback?.() || 0,
                    }));
                childWithSubscriptions?.forEach((child) => initialValuePromises.push(child));
            });

            const initialValues = await Promise.all(initialValuePromises);

            initialValues.forEach((keyValue) => {
                const countRef = pushNotificationStore.publish(keyValue.key, keyValue.initialValue);
                counts.set(keyValue.key, countRef);
            });

            pushNotificationCounts.value = counts;
        }

        onBeforeMount(() => {
            sideBar.updateMenuItems();
            menuItems.push(...sideBar.menuItems);
            subscribePushNotification(menuItems);
        });

        return {
            menuItems,
            pushNotificationCounts,
        };
    },
});
