import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from './dataAccessResponse';
import ShippingNotice from '@/domain/ShippingNotice';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class ShippingNoticeApiService {
    public async getFailedShippingNotices(): Promise<DataAccessResponse<ShippingNotice[]>> {
        const response: AxiosResponse<DataAccessResponse<ShippingNotice[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/ShippingNotice/failed`,
        });

        return new DataListAccessResponseClass<ShippingNotice>(response, ShippingNotice).response;
    }

    public async rejectShippingNotice(id: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ShippingNotice/failed-rejection/${id}`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async revalidateAllShippingNotices(): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ShippingNotice/failed-revalidation`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async revalidateShippingNotice(id: number): Promise<DataAccessResponse<string[]>> {
        const response: AxiosResponse<DataAccessResponse<string[]>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ShippingNotice/failed-revalidation/${id}`,
        });

        return new DataAccessResponseClass<string[]>(response).response;
    }

    public async getShippingNotice(id: number): Promise<DataAccessResponse<ShippingNotice>> {
        const response: AxiosResponse<DataAccessResponse<ShippingNotice>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/ShippingNotice/${id}`,
        });

        return new DataAccessResponseClass<ShippingNotice>(response, ShippingNotice).response;
    }

    public async updateShippingNotice(shippingNotice: ShippingNotice): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ShippingNotice/update`,
            data: shippingNotice,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
