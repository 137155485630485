import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from './dataAccessResponse';
import PartnerOrder from '@/domain/PartnerOrder';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class PartnerOrderApiService {
    public async getFailedPartnerOrders(): Promise<DataAccessResponse<PartnerOrder[]>> {
        const response: AxiosResponse<DataAccessResponse<PartnerOrder[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/PartnerOrder/failed`,
        });

        return new DataListAccessResponseClass<PartnerOrder>(response, PartnerOrder).response;
    }

    public async rejectPartnerOrder(id: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/PartnerOrder/failed-rejection/${id}`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async revalidateAllPartnerOrders(): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/PartnerOrder/failed-revalidation`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async revalidatePartnerOrder(id: number): Promise<DataAccessResponse<string[]>> {
        const response: AxiosResponse<DataAccessResponse<string[]>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/PartnerOrder/failed-revalidation/${id}`,
        });

        return new DataAccessResponseClass<string[]>(response).response;
    }

    public async getPartnerOrder(id: number): Promise<DataAccessResponse<PartnerOrder>> {
        const response: AxiosResponse<DataAccessResponse<PartnerOrder>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/PartnerOrder/${id}`,
        });

        return new DataAccessResponseClass<PartnerOrder>(response, PartnerOrder).response;
    }

    public async updatePartnerOrder(partnerOrder: PartnerOrder): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/PartnerOrder/update`,
            data: partnerOrder,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
